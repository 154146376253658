// Import necessary modules
import { supabase } from './src/scripts/supabase.js';
import { checkAuth } from './src/scripts/auth.js';
import { initializeHeader } from './src/scripts/header.js';
import { loadSideNav } from './src/scripts/sidebar-nav.js';

let modalOpenedFrom = null;
let isUserSubscribed = false;
let session = null;
let subscription = null;
let videosLoaded = false;

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function toSentenceCase(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function getEnv(key, defaultValue) {
  if (window.ENV && window.ENV[key] !== undefined) {
    return window.ENV[key];
  }
  if (import.meta.env[key] !== undefined) {
    return import.meta.env[key];
  }
  return defaultValue;
}

const SUPABASE_URL = getEnv('VITE_SUPABASE_URL', '');
const SUPABASE_ANON_KEY = getEnv('VITE_SUPABASE_ANON_KEY', '');
const ENVIRONMENT = getEnv('ENVIRONMENT', 'production');
const FRONTEND_URL = getEnv('FRONTEND_URL', window.location.origin);
const STRIPE_PRICE_ID = getEnv('STRIPE_PRICE_ID', '');

let userData = null;
let isAuthenticated = false; // Declare the variable here
let isSubscribed = false;     // Declare the variable here

async function handleNotSubscribe(session, subscription) {
  const notSubscribeSection = document.getElementById('notSubscribeSection');
  const getProLink = document.querySelector('a[href="/getpro"]');
  
  if (notSubscribeSection && getProLink) {
    if (session && subscription) {
      notSubscribeSection.classList.add('hidden');
      getProLink.classList.add('hidden');
    } else {
      notSubscribeSection.classList.remove('hidden');
      getProLink.classList.remove('hidden');
    }
  } else {
    console.error('notSubscribeSection or getProLink not found');
  }
}

async function initializePage() {
  if (videosLoaded) {
    console.log('Videos already loaded, skipping initialization');
    return;
  }

  const authResult = await checkAuth();
  session = authResult.session;
  subscription = authResult.subscription;
  
  console.log('Auth status:', session ? 'Authenticated' : 'Not authenticated');
  console.log('Subscription status:', subscription ? 'Subscribed' : 'Not subscribed');

  isUserSubscribed = !!subscription;
  isAuthenticated = !!session;
  isSubscribed = !!subscription;

  if (session && subscription) {
    if (window.location.pathname === '/' || window.location.pathname === '') {
      window.location.replace('/motion-in-motion');
      return;
    }
  }

  await handleNotSubscribe(session, subscription);
  if (isAuthenticated) {
    saveSession(session);
  }

  await initializeHeader(session, subscription);
  const currentPage = 'index';
  await loadSideNav(currentPage, session, subscription);
  await loadMainPageContent(session, subscription);
  setupPopStateHandler();
  await handleVideoInUrl();
  setupModalCloseButton();

  videosLoaded = true;
}

async function loadMainPageContent(session, subscription) {
  const selectedplatform = getSelectedplatform();
  await loadVideos(session, subscription);
  updateplatformSelection(selectedplatform);
}

function setupPopStateHandler() {
  window.addEventListener('popstate', async (event) => {
    const modal = document.getElementById('modal');
    if (modal && !modal.classList.contains('hidden')) {
      closeModal(true);
    }

    const pathParts = window.location.pathname.split('/').filter(Boolean);
    if (pathParts.length === 1 && pathParts[0].length === 36) {
      await handleVideoInUrl();
    } else if (window.location.pathname === '/') {
      await loadMainPageContent();
    } else {
      await loadMainPageContent();
    }
  });
}

async function handleVideoInUrl() {
  const pathParts = window.location.pathname.split('/').filter(Boolean);
  if (pathParts.length === 1 && pathParts[0].length === 36) {
    const videoId = pathParts[0];

    try {
      let { data: video, error: flowError } = await supabase
        .from('flow_videos')
        .select(`
          *,
          apps:app_id (
            app_name,
            normalised_app_name,
            logo_url,
            description
          )
        `)
        .eq('video_id', videoId)
        .single();

      if (!video || flowError) {
        let { data: interactionVideo, error: interactionError } = await supabase
          .from('interaction_videos')
          .select(`
            *,
            apps:app_id (
              app_name,
              normalised_app_name,
              logo_url,
              description
            )
          `)
          .eq('video_id', videoId)
          .single();

        if (interactionVideo) {
          video = interactionVideo;
        }
      }

      if (!video) {
        window.history.replaceState(null, '', '/');
        await loadMainPageContent();
        return;
      }

      if (document.getElementById('videosContainer').children.length === 0) {
        await loadMainPageContent();
      }

      await openModal(videoId, video.apps.normalised_app_name, '/');
    } catch (error) {
      console.error('Error checking video existence:', error);
      window.history.replaceState(null, '', '/');
      await loadMainPageContent();
    }
  }
}

function setupModalCloseButton() {
  const closeModalButton = document.getElementById('closeModalButton');
  if (closeModalButton) {
    closeModalButton.addEventListener('click', (event) => {
      event.preventDefault();
      closeModal(false);
    });
  }
}

function getSelectedplatform() {
  const pathParts = window.location.pathname.split('/').filter(part => part);
  return pathParts[0] || 'motion-in-motion';
}

async function loadVideos(session, subscription) {
  const videosContainer = document.getElementById('videosContainer');

  if (!videosContainer) {
    console.error('Videos container is missing!');
    return;
  }

  // Clear the container only if it's not already empty
  if (videosContainer.children.length > 0) {
    console.log('Clearing existing videos');
    videosContainer.innerHTML = '';
  }

  try {
    let allVideos = [];
    const limit = (session && subscription) ? 100 : 20;

    console.log(`Fetching up to ${limit} videos`);

    let { data: flowVideos, error: flowError } = await supabase
      .from('flow_videos')
      .select(`
        *,
        apps:app_id (
          app_name,
          normalised_app_name,
          logo_url,
          description
        )
      `)
      .order('added_on', { ascending: false })
      .limit(limit);

    if (flowError) throw flowError;
    allVideos = allVideos.concat(flowVideos);

    let { data: interactionVideos, error: interactionError } = await supabase
      .from('interaction_videos')
      .select(`
        *,
        apps:app_id (
          app_name,
          normalised_app_name,
          logo_url,
          description
        )
      `)
      .order('added_on', { ascending: false })
      .limit(limit);

    if (interactionError) throw interactionError;
    allVideos = allVideos.concat(interactionVideos);

    console.log(`Total videos fetched: ${allVideos.length}`);

    // Shuffle the videos
    allVideos = allVideos.sort(() => 0.5 - Math.random());

    // Limit to 20 videos for non-authenticated or non-subscribed users
    if (!session || !subscription) {
      allVideos = allVideos.slice(0, 20);
      console.log('Limited to 20 videos for non-subscribed users');
    }

    console.log(`Videos to display: ${allVideos.length}`);

    const fragment = document.createDocumentFragment();
    allVideos.forEach(video => {
      const videoElement = createVideoElement(video);
      if (videoElement) {
        fragment.appendChild(videoElement);
        if (isIOS()) {
          videoElement.querySelector('video').play().catch(() => {});
        }
      }
    });
    videosContainer.appendChild(fragment);

    console.log(`Actual videos displayed: ${videosContainer.children.length}`);

  } catch (error) {
    console.error('Error loading videos:', error);
    videosContainer.innerHTML = `<p class="text-center col-span-full">Error loading videos. Please try again later.</p>`;
  }
}

function createVideoElement(data) {
  const { video_url, apps, video_id } = data;
  if (!video_url) {
      console.error('Video URL missing for videoId:', video_id);
      return null;
  }

  const appName = apps?.app_name ? toSentenceCase(apps.app_name) : 'Unknown Company';
  const appLogo = apps?.logo_url || 'path/to/default/logo.png';
  const appSlug = apps?.normalised_app_name || appName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  const appUrl = `${window.location.origin}/${appSlug}`;

  const videoItem = document.createElement('div');
  videoItem.className = 'video-item grid-hover cursor-pointer';
  videoItem.innerHTML = `
      <div class="flex justify-center items-center w-full rounded-3xl bg-stone-100 h-72 p-4 relative">
          <div class="skeleton-loader absolute inset-4 flex items-center justify-center bg-stone-300 rounded-2xl animate-pulse">
              <svg class="w-10 h-10 text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z"/>
              </svg>
              <span class="sr-only">Loading...</span>
          </div>
          <video src="${video_url}" class="rounded-2xl w-auto object-cover opacity-0 transition-opacity duration-300" style="height: 100%;" muted playsinline preload="auto" controlslist="nodownload noplaybackrate" disablePictureInPicture></video>
      </div>
      <div class="flex items-center text-sm app-link mt-2.5" data-app-url="${appUrl}">
          <img src="${appLogo}" class="w-6 h-6 rounded-full object-cover border border-stone-100 mr-1.5">
          <span class="font-medium text-sm hover:underline">${appName}</span>
      </div>
  `;
  videoItem.dataset.videoId = video_id;

  // Add click event listener to the app name/logo
  const appLinkElement = videoItem.querySelector('.app-link');
  appLinkElement.addEventListener('click', () => {
      navigateToCompanyPage(appUrl);
  });

  const videoElement = videoItem.querySelector('video');
  const skeletonLoader = videoItem.querySelector('.skeleton-loader');

  let videoLoaded = false;
  let minDisplayTimeElapsed = false;

  videoElement.addEventListener('loadeddata', () => {
      videoLoaded = true;
      hideSkeletonIfReady();
  });

  // Set a minimum display time for the skeleton loader
  setTimeout(() => {
      minDisplayTimeElapsed = true;
      hideSkeletonIfReady();
  }, 1000); // 1 second minimum display time

  function hideSkeletonIfReady() {
      if (videoLoaded && minDisplayTimeElapsed) {
          skeletonLoader.style.opacity = '0';
          videoElement.style.opacity = '1';
          // Remove the skeleton loader from the DOM after the transition
          setTimeout(() => {
              skeletonLoader.remove();
          }, 300); // Match this to the transition duration
      }
  }

  videoElement.addEventListener('error', () => {
      console.error(`Error loading video (${video_id}):`, videoElement.error);
      videoItem.remove();  // Remove the video item if it fails to load
  });

  setupVideoInteractions(videoItem, video_id);
  return videoItem;
}

function navigateToCompanyPage(appUrl) {
  localStorage.setItem('previousUrl', window.location.pathname);
  window.location.href = appUrl;
}

async function setupVideoInteractions(videoItem, videoId) {
  const videoElement = videoItem.querySelector('video');
  let isPlaying = false;

  if (!isIOS()) {
    videoItem.addEventListener('mouseover', () => {
      if (!isPlaying) {
        videoElement.play().then(() => {
          isPlaying = true;
        }).catch(error => {
          if (error.name !== 'AbortError') {
            console.error("Error playing video on hover: ", error);
          }
        });
      }
    });

    videoItem.addEventListener('mouseleave', () => {
      if (isPlaying) {
        videoElement.pause();
        videoElement.currentTime = 0;
        isPlaying = false;
      }
    });
  }

  videoElement.addEventListener('click', (event) => {
    event.preventDefault();
    openModal(videoId, videoItem.dataset.appSlug, window.location.pathname);
  });

  videoElement.addEventListener('contextmenu', (event) => {
    event.preventDefault();
  });

  videoElement.pause();
  videoElement.currentTime = 0;
}

function updateplatformSelection(selectedplatform) {
  const platformTabs = document.querySelectorAll('.platform-tab');
  platformTabs.forEach(tab => {
    const isSelected = tab.dataset.platform === selectedplatform;
    tab.classList.toggle('border-color-primary', isSelected);
    tab.classList.toggle('text-color-primary', isSelected);
    tab.classList.toggle('font-bold', isSelected);
    tab.classList.toggle('border-transparent', !isSelected);
    tab.classList.toggle('text-gray-500', !isSelected);
    tab.classList.toggle('hover:border-gray-200', !isSelected);
    tab.dataset.selected = isSelected.toString();
  });
}

async function openModal(videoId, appSlug, originalUrl = null) {
  const modal = document.getElementById('modal');
  const modalVideo = document.getElementById('modalVideo');
  const modalCompanyName = document.getElementById('modalCompanyName');

  if (!modal || !modalVideo || !modalCompanyName) {
    console.error('Essential modal elements are missing!');
    return;
  }

  try {
    let { data: video, error: flowError } = await supabase
      .from('flow_videos')
      .select(`
        *,
        apps:app_id (
          app_name,
          normalised_app_name,
          logo_url,
          description
        )
      `)
      .eq('video_id', videoId)
      .single();

    if (!video || flowError) {
      let { data: interactionVideo, error: interactionError } = await supabase
        .from('interaction_videos')
        .select(`
          *,
          apps:app_id (
            app_name,
            normalised_app_name,
            logo_url,
            description
          )
        `)
        .eq('video_id', videoId)
        .single();

      if (!interactionVideo || interactionError) {
        throw new Error('Video not found in both tables');
      }

      video = interactionVideo;
    }

    const { video_url, platform, apps } = video;
    const { app_name, logo_url } = apps;
    const formattedCompanyName = toSentenceCase(app_name);

    if (!video_url) {
      throw new Error('No video URL found.');
    }

    appSlug = appSlug || apps.normalised_app_name;

    modalVideo.src = video_url;
    modalVideo.load();
    modalVideo.controlsList = isUserSubscribed ? "noplaybackrate" : "nodownload noplaybackrate";

    modalVideo.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      return false;
    });

    modalCompanyName.href = `/${appSlug}`;
    modalCompanyName.innerHTML = `<img src="${logo_url}" class="w-9 h-9 rounded-md object-cover bg-stone-100 mr-2" alt="${formattedCompanyName} Logo">${formattedCompanyName}`;
    modal.classList.remove('hidden');
    document.body.style.overflow = 'hidden';

    modalVideo.classList.toggle('video-fit-modal', platform === 'android' || platform === 'ios');

    const newUrl = `/${videoId}`;

    modalOpenedFrom = originalUrl || window.location.pathname;

    history.pushState(
      { 
        modalContext: {
          videoId,
          appSlug,
          originalUrl: modalOpenedFrom
        }
      },
      '',
      newUrl
    );
  } catch (error) {
    console.error('Error fetching video:', error);
    alert('Failed to load video. Please try again later.');
  }
}

function closeModal(isPopState = false) {
  const modal = document.getElementById('modal');
  const modalVideo = document.getElementById('modalVideo');
  modalVideo.pause();
  modal.classList.add('hidden');
  document.body.style.overflow = 'auto';

  if (!isPopState && modalOpenedFrom) {
      history.pushState(null, '', modalOpenedFrom);
      modalOpenedFrom = null;
  } else if (window.location.pathname.includes('/')) {
      history.pushState(null, '', '/');
  }
}

function setupPlatformLinks() {
  // Select all platform links in the sidebar
  const platformLinks = document.querySelectorAll('.platform-link');

  // Add event listeners to each platform link
  platformLinks.forEach(link => {
    link.addEventListener('click', (event) => {
      event.preventDefault(); // Prevent default behavior

      // Get the platform from the data attribute
      const platform = link.getAttribute('data-platform');

      if (!platform) {
        console.error('Platform not found in the data attribute');
        return;
      }

      // Construct the URL for the platform
      const newUrl = `/browse/${platform}/flows`; // Adjust URL pattern as needed

      // Navigate to the new URL
      window.location.href = newUrl;
    });
  });
}

document.addEventListener('click', (event) => {
  const modal = document.getElementById('modal');
  if (modal && !modal.classList.contains('hidden') && event.target === modal) {
    closeModal(false);
  }
});

document.addEventListener('click', (event) => {
  const modal = document.getElementById('modal');
  if (modal && !modal.classList.contains('hidden') && event.target === modal) {
    closeModal(false);
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const hamburgerMenuIcon = document.getElementById('hamburgerMenuIcon');
  const sidebar = document.getElementById('sidebar');
  const closeSidebarButton = document.getElementById('closeSidebarButton');
  const sidebarOverlay = document.getElementById('sidebarOverlay');

  // Function to open sidebar
  function openSidebar() {
      sidebar.classList.remove('translate-x-full');
      sidebar.classList.add('translate-x-0');
      sidebar.classList.remove('hidden');
      // Show overlay
      if (sidebarOverlay) {
          sidebarOverlay.classList.remove('hidden');
      }
      // Hide hamburger menu icon, show close button
      hamburgerMenuIcon.classList.add('hidden');
      closeSidebarButton.classList.remove('hidden');
      // Prevent body scrolling
      document.body.style.overflow = 'hidden';
  }

  // Function to close sidebar
  function closeSidebar() {
      sidebar.classList.remove('translate-x-0');
      sidebar.classList.add('translate-x-full');
      // After transition ends, hide the sidebar
      setTimeout(function() {
          sidebar.classList.add('hidden');
      }, 300); // Duration matches the transition duration
      // Hide overlay
      if (sidebarOverlay) {
          sidebarOverlay.classList.add('hidden');
      }
      // Show hamburger menu icon, hide close button
      hamburgerMenuIcon.classList.remove('hidden');
      closeSidebarButton.classList.add('hidden');
      // Allow body scrolling
      document.body.style.overflow = '';
  }

  // Event listener for hamburger menu icon
  if (hamburgerMenuIcon) {
      hamburgerMenuIcon.addEventListener('click', function() {
          openSidebar();
      });
  }

  // Close sidebar when clicking outside (on overlay)
  if (sidebarOverlay) {
      sidebarOverlay.addEventListener('click', function() {
          closeSidebar();
      });
  }
});

document.addEventListener('DOMContentLoaded', async () => {
  await initializePage();
  await initializeHeader();
  
  setupPlatformLinks();
});